export default [
    {
        title: 'User Management',
        icon: 'UsersIcon',
        tagVariant: 'light-warning',
        children: [
          {
            title: 'User',
            route: 'user-management',
          },
        ],
    },
]  