<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav navbar-mobile">
      <li class="nav-item d-flex align-items-center">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
      <li class="logo-navbar-mobile">
        <b-link to="/home" class="navbar-logo">
          <img src="@/assets/images/logo/logo-dialog.png" alt="">
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <ul class="center-menu mb-0">
        <li>
          <b-link to="home">
            <feather-icon
              class="menu-icons"
              icon="HomeIcon"
              size="21"
            />
            Home
          </b-link>
        </li>
        <li>
          <b-link to="remote">
            <feather-icon
              class="menu-icons"
              icon="SmartphoneIcon"
              size="21"
            />
            Remote
          </b-link>
        </li>
        <li>
          <b-link to="history">
            <feather-icon
              class="menu-icons"
              icon="ClockIcon"
              size="21"
            />
            History
          </b-link>
        </li>
        <li>
          <b-link to="user-management">
            <feather-icon
              class="menu-icons"
              icon="UsersIcon"
              size="21"
            />
            User Management
          </b-link>
        </li>
      </ul>
    </div>

    <b-navbar-nav class="nav align-items-center">
      <b-link class="mercatus-logo">
        <img src="@/assets/images/logo/logo-mercatus-white.png" alt="">
      </b-link>
      <user-dropdown class="d-none d-lg-block"/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} 
from 'bootstrap-vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
