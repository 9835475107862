export default [
  {
    title: 'Remote',
    icon: 'SmartphoneIcon',
    children: [
      {
        title: 'Devices',
        route: 'device',
      },
      {
        title: 'Devices-2',
        route: 'device-2',
      },
      {
        title: 'remote-1',
        route: 'remote',
      },
      {
        title: 'remote-2',
        route: 'remote-2',
      },
      {
        title: 'Submenu4',
        route: '',
      },
      {
        title: 'Submenu5',
        route: '',
      },
    ],
  },
]
