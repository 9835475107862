export default [
  {
    header: 'Support',
  },
  {
    title: 'Support',
    route: 'pages-faq',
    icon: 'none',
  },
]
