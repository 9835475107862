export default [
  {
    title: 'History',
    icon: 'ClockIcon',
    tagVariant: 'light-success',
    children: [
      {
        title: 'History',
        route: 'history',
      },
    ],
  },
]
